




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  @Prop({ default: null }) readonly completedSections!: number;
  @Prop({ default: null }) readonly totalSections!: number;

  get style() {
    const completedPercentage = Math.round(
      (this.completedSections / this.totalSections) * 100
    );
    const unCompletedPercentage = 100 - completedPercentage;
    return {
      background: `linear-gradient(to right,
          var(--defa-oreon-color-primary),
          var(--defa-oreon-color-primary) ${completedPercentage}%,
          white ${completedPercentage}%,
          white ${unCompletedPercentage}%
        )`,
    };
  }
}
