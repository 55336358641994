import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { config } from "vuex-module-decorators";
import {
  clearLocalStorageIfOnNewVersion,
  getVuexLocalStorageKey,
  onLocal,
} from "@defa-as/utils";
import type { FormModule } from "@/store/modules/form";
import type { ErrorModule } from "@/store/modules/error";
import type { OrderModule } from "@/store/modules/order";

Vue.use(Vuex);
config.rawError = true;

const plugins = [];

interface StoreWithModules {
  form: FormModule;
  order: OrderModule;
  error: ErrorModule;
}

const orderId = window.location.pathname.match(/\/\d{6}(?=\/)/)?.[0].slice(1);

const localStorageKey = `${getVuexLocalStorageKey(
  "vuex-homecheck",
  true
)}-${orderId}`;
clearLocalStorageIfOnNewVersion(localStorageKey);
plugins.push(
  new VuexPersist({
    key: localStorageKey,
    storage: window.localStorage,
    modules: ["form"],
  }).plugin
);

export default new Vuex.Store<StoreWithModules>({
  plugins,
  strict: onLocal(),
});
