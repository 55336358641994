





















import { Component, Vue } from "vue-property-decorator";
import ProgressArrows from "@/components/progress/ProgressArrows.vue";
import ProgressBar from "@/components/progress/ProgressBar.vue";
import ProgressText from "@/components/progress/ProgressText.vue";
import { formModule } from "@/store/modules/form";

@Component({
  components: {
    ProgressText,
    ProgressArrows,
    ProgressBar,
  },
})
export default class TheProgressBar extends Vue {
  get formModule() {
    return formModule;
  }
}
