import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({ name: "error", dynamic: true, store, namespaced: true })
export class ErrorModule extends VuexModule {
  errorMessage = "";

  @Mutation
  SET_ERROR({ errorMessage }: { errorMessage: string }) {
    this.errorMessage = errorMessage;
  }

  @Mutation
  CLEAR_ERROR() {
    this.errorMessage = "";
  }

  get error() {
    return this.errorMessage;
  }
}

export const errorModule = getModule(ErrorModule);
