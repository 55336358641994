





























import { Component, Vue } from "vue-property-decorator";
import {
  DefaLoaderCircle,
  DefaTransitionFadeSlide,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import TheProgressBar from "@//components/TheProgressBar.vue";
import SectionSplash from "@/components/sections/SectionSplash.vue";
import SectionInfo from "@/components/sections/SectionInfo.vue";
import SectionOptions from "@/components/sections/SectionOptions.vue";
import SectionUpload from "@/components/sections/SectionUpload.vue";
import SectionFreeText from "@/components/sections/SectionFreeText.vue";
import SectionSummary from "@/components/sections/SectionSummary.vue";
import SectionInput from "@/components/sections/SectionInput.vue";
import { formModule } from "@/store/modules/form";
import type {
  FormSection,
  GeneralFormSection,
  SectionUpload as SectionUploadType,
} from "@/store/types/form";
import { Nullable } from "@defa-as/utils";

@Component({
  components: {
    SectionUpload,
    SectionOptions,
    SectionSplash,
    SectionInfo,
    SectionFreeText,
    SectionSummary,
    SectionInput,
    DefaLoaderCircle,
    DefaTransitionFadeSlide,
    DefaTransitionFadeOutIn,
    TheProgressBar,
  },
})
export default class TheForm extends Vue {
  get activeSection() {
    return formModule.activeSection;
  }

  get label() {
    return (name: string) => `formConfig.${name}.label`;
  }

  get isProgressBarVisible() {
    return formModule.getStep !== 0;
  }

  get transitionStartAndEnd() {
    if (formModule.stepDirection === "forward") {
      return {
        yPositionStart: -1,
        yPositionEnd: 1,
      };
    }
    return {
      yPositionStart: 1,
      yPositionEnd: 1,
    };
  }

  get progressionText() {
    return (section: FormSection | SectionUploadType) => {
      if (
        section.type !== "section-options" &&
        section.type !== "section-summary"
      ) {
        return this.translationIfExists(
          "progressionText",
          section.name,
          this.$t("formConfig.default.progressionText") as string
        );
      }
      return null;
    };
  }

  get heading() {
    return (name: string) => this.translationIfExists("heading", name);
  }

  get bodyItems() {
    return (section: FormSection | SectionUploadType) => {
      if (section.type === "section-info") {
        return formModule.uploadSectionNames.map((uploadSectionName) =>
          this.$t(`formConfig.${section.name}.body.${uploadSectionName}`)
        );
      }
      return null;
    };
  }

  get translationIfExists() {
    return (
      translationType: string,
      name: string,
      defaultIfNotExists: Nullable<string> = null
    ) => {
      const translationKey = `formConfig.${name}.${translationType}`;
      if (this.$te(translationKey)) {
        return this.$t(translationKey);
      }
      return defaultIfNotExists;
    };
  }

  attrsForSection(section: GeneralFormSection) {
    if (section.attrs) {
      return Object.entries(section.attrs).map(
        ([attributeName, attrConfig]) => ({
          [attributeName]: attrConfig.requiresTranslation
            ? this.$t(attrConfig.value)
            : attrConfig.value,
        })
      );
    }
    return null;
  }

  created() {
    window.addEventListener("keyup", this.keyListener);
  }

  beforeDestroy() {
    window.removeEventListener("keyup", this.keyListener);
  }

  keyListener(e: KeyboardEvent) {
    switch (e.code) {
      case "ArrowUp":
        formModule.goBackward();
        break;
      case "ArrowLeft":
        formModule.goBackward();
        break;
      case "ArrowRight":
        if (formModule.isForwardAvailable) {
          formModule.goForward();
        }
        break;
      case "ArrowDown":
        if (formModule.isForwardAvailable) {
          formModule.goForward();
        }
        break;
    }
  }
}
