
















import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import BaseOptionKeyHint from "@/components/BaseOptionKeyHint.vue";

@Component({
  components: {
    BaseOptionKeyHint,
  },
})
export default class BaseOption extends Vue {
  @Prop({ required: true }) readonly option!: string;
  @Prop({ required: true }) readonly keyHint!: string;
  @Prop({ default: false }) readonly selected!: boolean;
  @Ref() readonly optionEl!: HTMLButtonElement;
  observer!: IntersectionObserver;

  get optionText() {
    return this.$t(`shared.homecheckOption.value.${this.option}`);
  }

  mounted() {
    this.observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        return window.addEventListener("keyup", this.keyListener);
      }
      window.removeEventListener("keyup", this.keyListener);
    });
    this.observer.observe(this.optionEl);
  }

  beforeDestroy() {
    this.observer.unobserve(this.optionEl);
  }

  select() {
    this.$emit("click");
  }

  keyListener(event: KeyboardEvent) {
    if (event.key.toUpperCase() === this.keyHint) {
      this.select();
    }
  }
}
