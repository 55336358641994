















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { formModule } from "@/store/modules/form";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";

@Component({
  components: {
    SectionLabel,
    DefaBaseButton,
  },
})
export default class SectionSplash extends Vue {
  @Prop({ default: "" }) heading!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) progressionText!: string;

  get formModule() {
    return formModule;
  }
}
