



































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { formModule } from "@/store/modules/form";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";
import SectionNumber from "@/components/sections/section-parts/SectionNumber.vue";

@Component({
  inheritAttrs: false,
  components: {
    SectionLabel,
    SectionNumber,
    DefaBaseButton,
  },
})
export default class SectionInput extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly progressionText!: string;
  inputIsFocused = false;

  get validationErrorMessage() {
    return this.formModule.getFieldValidationErrorMessage(this.name);
  }

  get value() {
    return this.formModule.getFieldValue(this.name) as string;
  }

  set value(value: string) {
    this.formModule.setFormValue({
      name: this.name,
      value,
    });
  }

  get formModule() {
    return formModule;
  }

  onKeyup(e: KeyboardEvent) {
    if (e.code === "ArrowLeft" || e.code === "ArrowRight") {
      e.stopPropagation();
    }
  }

  onFocus() {
    this.inputIsFocused = true;
  }

  onBlur() {
    this.inputIsFocused = false;
  }
}
