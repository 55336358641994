













































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { formModule } from "@/store/modules/form";
import { orderModule } from "@/store/modules/order";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";
import type { SummaryAnswerType } from "@/store/types/form";

@Component({
  components: {
    SectionLabel,
    DefaBaseButton,
  },
})
export default class SectionSummary extends Vue {
  @Prop({ required: true }) readonly label!: string;

  get question() {
    return (name: string) => {
      const overviewLabel = `formConfig.${name}.summaryLabel`;
      if (this.$te(overviewLabel)) {
        return this.$t(overviewLabel);
      }
      return this.$t(`formConfig.${name}.label`);
    };
  }

  get optionText() {
    return (value: string) => this.$t(`shared.homecheckOption.value.${value}`);
  }

  get isPluralTranslationKey() {
    return (type: SummaryAnswerType) => type === "plural-translation-key";
  }

  get isSingleTranslationKey() {
    return (type: SummaryAnswerType) => type === "single-translation-key";
  }

  get isOption() {
    return (type: SummaryAnswerType) => type === "option";
  }

  get formModule() {
    return formModule;
  }

  onClickSubmit() {
    this.formModule.submitForm({
      orderId: orderModule.id,
      secret: orderModule.secret,
    });
  }

  goToStep(step: number) {
    this.formModule.SET_STEP({ step });
  }
}
