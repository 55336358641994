import Vue from "vue";
import {
  configureSentry,
  onLocalOrTest,
  setLangOnHtmlElement,
  setupThemingOnRootElement,
  RELEASE_VERSION,
} from "@defa-as/utils";
import { setupValidation } from "@/validation";

export const setup = () => {
  configureSentry(Vue, RELEASE_VERSION);
  setupThemingOnRootElement({
    colorPrimary: "black",
    colorAccent: "#eee",
  });
  setupValidation();
  setLangOnHtmlElement();

  if (onLocalOrTest()) {
    Vue.config.devtools = true;
  }
};
