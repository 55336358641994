import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type { Order } from "@defa-as/utils";
import { defaRetailerGroupHandle, homecheckOptions } from "@defa-as/utils";
import { formModule } from "@/store/modules/form";

@Module({ name: "order", dynamic: true, store, namespaced: true })
export class OrderModule extends VuexModule {
  data: Pick<Order, "id" | "secret" | "retailer" | "orderLines"> = {
    id: "",
    secret: "",
    retailer: null,
    orderLines: [],
  };

  get id() {
    return this.data.id;
  }

  get secret() {
    return this.data.secret;
  }

  get retailer() {
    return this.data.retailer;
  }

  get retailerGroup() {
    return this.data.retailer?.group;
  }

  get chargerHasInternetCapabilities() {
    if (this.charger) {
      return (
        this.charger.supportsEthernet ||
        this.charger.supportsWifi ||
        this.charger.supportsMobile
      );
    }
    // If we can't determine the charger, we assume it has internet capabilities
    return true;
  }

  // "productType" is not consistently set (e.g. Elli chargers), so this may be undefined
  get charger() {
    return this.data.orderLines.find(
      (product) => product.productType === "chargingStation"
    );
  }

  get hasNonDefaRetailer() {
    return this.data.retailer?.group.handle !== defaRetailerGroupHandle;
  }

  @Mutation
  ORDER_SET_ID({ id }: { id: string }) {
    this.data.id = id;
  }

  @Mutation
  ORDER_SET_SECRET({ secret }: { secret: string }) {
    this.data.secret = secret;
  }

  @Mutation
  ORDER_SET_RETAILER({ retailer }: { retailer: Order["retailer"] }) {
    this.data.retailer = retailer;
  }

  @Mutation
  ORDER_SET_ORDER_LINES({ orderLines }: { orderLines: Order["orderLines"] }) {
    this.data.orderLines = orderLines;
  }

  @Action
  async initializeOrder({
    id,
    secret,
    retailer,
    orderLines,
  }: {
    id: string;
    secret: string;
    retailer: Order["retailer"];
    orderLines: Order["orderLines"];
  }) {
    this.ORDER_SET_ID({ id });
    this.ORDER_SET_SECRET({ secret });
    this.ORDER_SET_RETAILER({ retailer });
    this.ORDER_SET_ORDER_LINES({ orderLines });
    if (this.chargerHasInternetCapabilities) {
      await formModule.setInternetConnectionOptions({
        options: homecheckOptions.internetConnection(this.charger),
      });
    } else {
      await formModule.hideInternetConnectionQuestion();
    }
  }
}

export const orderModule = getModule(OrderModule);
