<template>
  <span class="hide-tablet">
    {{ $t("progress.completedText", { completedSections, totalSections }) }}
  </span>
</template>

<script>
export default {
  name: "ProgressText",
  props: {
    completedSections: {
      type: Number,
      default: null,
    },
    totalSections: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@defa-as/utils/dist/styles";

.hide-tablet {
  @include styles.media("<tablet") {
    display: none;
  }
}
</style>
