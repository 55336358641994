import "reflect-metadata";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import i18n from "@/i18n";
import store from "@/store";
import { setup } from "@/setup";

setup();

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
