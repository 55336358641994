





import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaFullPageLoader } from "@defa-as/components";
import { formModule } from "@/store/modules/form";
import TheForm from "@/components/TheForm.vue";
import { initializationModule } from "@/store/modules/initialization";

@Component({
  components: {
    TheForm,
    DefaFullPageLoader,
  },
})
export default class ViewForm extends Vue {
  @Prop({ required: true }) readonly orderId!: string;
  @Prop({ required: true }) readonly secret!: string;

  get formModule() {
    return formModule;
  }

  created() {
    initializationModule.initialize({
      orderId: this.orderId,
      secret: this.secret,
    });
  }
}
