import { homecheckOptions, validationConfigForFields } from "@defa-as/utils";
import { httpClient } from "@/http";
import router, { ROUTE_NAMES } from "@/router";
import i18n from "@/i18n";

import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {
  FormSection,
  FormSectionValue,
  SectionNameAndValuePayload,
  SectionNamePayload,
  SectionOptions,
  SectionUpload,
  StepDirection,
  SummaryAnswer,
} from "@/store/types/form";
import store from "@/store";
import { validate } from "vee-validate";

type Section = FormSection | SectionOptions | SectionUpload;

// Workaround for not being able to use getters in mutations
const findSectionByName = (sections: Section[], name: string) =>
  sections.find((section) => section.name === name);

@Module({
  name: "form",
  dynamic: true,
  store,
  preserveState: !!store.state.form,
  namespaced: true,
})
export class FormModule extends VuexModule {
  step = 0;
  stepDirection: StepDirection = "forward";
  loading = false;
  sections: Section[] = [
    {
      name: "welcome",
      type: "section-splash",
      visible: true,
    },
    {
      name: "propertyId",
      type: "section-input",
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.propertyId,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "personalIdentificationNumber",
      type: "section-input",
      value: null,
      visible: true,
      attrs: {
        placeholder: {
          value: "(YY)YYMMDDXXXX",
          requiresTranslation: false,
        },
      },
      validation: {
        rules: validationConfigForFields.homecheck.personalIdentificationNumber,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "propertyType",
      type: "section-options",
      options: homecheckOptions.propertyType(),
      hideOtherStep: {
        stepName: "propertyOwnerApproval",
        oneOf: ["independentProperty"],
      },
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.propertyType,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "propertyOwnerApproval",
      type: "section-options",
      options: homecheckOptions.propertyOwnerApproval(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.propertyOwnerApproval,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "mainFuseCapacity",
      type: "section-options",
      options: homecheckOptions.mainFuseCapacity(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.mainFuseCapacity,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "voltage",
      type: "section-options",
      options: homecheckOptions.voltage(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.voltage,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "distributionBoardSpaceAvailable",
      type: "section-options",
      options: homecheckOptions.yesNoUnknown(),
      value: null,
      visible: true,
      validation: {
        rules:
          validationConfigForFields.homecheck.distributionBoardSpaceAvailable,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "cableLengthEstimate",
      type: "section-options",
      options: homecheckOptions.cableLengthEstimate(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.cableLengthEstimate,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "internetConnection",
      type: "section-options",
      options: [], // Will be initialized in "setInternetConnectionOptions" action
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.internetConnection,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "excavationNecessary",
      type: "section-options",
      options: homecheckOptions.yesNoUnknown(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.excavationNecessary,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "specializedDrillingNecessary",
      type: "section-options",
      options: homecheckOptions.yesNoUnknown(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.specializedDrillingNecessary,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "solarPanelsOrElectricHeating",
      type: "section-options",
      options: homecheckOptions.yesNo(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.solarPanelsOrElectricHeating,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "solarPanels",
      type: "section-options",
      options: homecheckOptions.yesNo(),
      value: null,
      visible: true,
      validation: {
        rules: validationConfigForFields.homecheck.solarPanels,
        valid: false,
        errorMessage: "",
      },
    },
    {
      name: "uploadInfo",
      type: "section-info",
      visible: true,
    },
    {
      name: "distributionBoardImages",
      type: "section-upload",
      value: [],
      visible: true,
    },
    {
      name: "distributionBoardSchemaImages",
      type: "section-upload",
      value: [],
      visible: true,
    },
    {
      name: "electricityMeterBoxImages",
      type: "section-upload",
      value: [],
      visible: true,
    },
    {
      name: "installationSiteImages",
      type: "section-upload",
      value: [],
      visible: true,
    },
    {
      name: "comment",
      type: "section-free-text",
      value: null,
      visible: true,
      attrs: {
        placeholder: {
          value: "formConfig.comment.placeholder",
          requiresTranslation: true,
        },
      },
    },
    {
      name: "summary",
      type: "section-summary",
      visible: true,
    },
  ];

  get getFieldValue() {
    return (name: string) => findSectionByName(this.sections, name)?.value;
  }

  get getFieldValidationErrorMessage() {
    return (name: string) =>
      findSectionByName(this.sections, name)?.validation?.errorMessage;
  }

  get activeSection() {
    return this.visibleSections[this.getStep];
  }

  get visibleSections() {
    return this.sections.filter((section) => section.visible);
  }

  get fillableSections() {
    return this.visibleSections.filter(
      (section) =>
        !["section-splash", "section-info", "section-summary"].includes(
          section.type
        )
    );
  }

  get uploadSectionNames() {
    return this.visibleSections
      .filter((section) => section.type === "section-upload")
      .map((section) => section.name);
  }

  get getFieldSectionNumber() {
    return (name: string) =>
      this.fillableSections.findIndex((section) => section.name === name) + 1;
  }

  get completedSections() {
    return this.fillableSections.filter((section) => {
      if (section.validation?.rules) {
        return section.validation.valid;
      }
      return section.type === "section-upload"
        ? section.value.length
        : section.value;
    }).length;
  }

  get totalSections() {
    return this.fillableSections.length;
  }

  get completedAllSections() {
    return this.getInCompleteSectionNumbers.length === 0;
  }

  get getInCompleteSectionNumbers() {
    return this.fillableSections
      .filter((section) => {
        if (section.validation?.rules) {
          return !section.validation.valid;
        }
        return false;
      })
      .map((section) => this.getFieldSectionNumber(section.name));
  }

  get hideOtherStepForCurrentStep() {
    return this.visibleSections[this.getStep].hideOtherStep;
  }

  get rulesForSection() {
    return (name: string) =>
      findSectionByName(this.sections, name)?.validation?.rules;
  }

  get getStep() {
    return this.step;
  }

  get getStepDirection() {
    return this.stepDirection;
  }

  get isLoading() {
    return this.loading;
  }

  get getFormSectionValues() {
    return this.visibleSections.reduce(
      (
        flattenedValues: Record<
          string,
          FormSectionValue | string[] | undefined
        >,
        section
      ) => {
        if (section.type === "section-upload") {
          flattenedValues[section.name] = section.value.map(({ id }) => id); // We're only interested in the file ids, not uploadUUIDs
        } else {
          flattenedValues[section.name] = section.value;
        }
        return flattenedValues;
      },
      {}
    );
  }

  get isOnFirstStep() {
    return this.step === 0;
  }

  get preSummaryStepIndex() {
    return this.visibleSections.findIndex(
      (section) => section.name === "summary"
    );
  }

  get isOnPreSummaryStep() {
    return this.step + 1 === this.preSummaryStepIndex;
  }

  get isOnLastStep() {
    return this.step === this.visibleSections.length - 1;
  }

  get isForwardAvailable() {
    if (this.isOnPreSummaryStep) {
      return this.completedAllSections;
    }
    return !this.isOnLastStep;
  }

  get summary() {
    return this.fillableSections.map((completedSection) => {
      const name = completedSection.name;
      let answer: SummaryAnswer;
      if (Array.isArray(completedSection.value)) {
        answer = {
          type: "plural-translation-key",
          value: "summary.answers.uploadedFiles",
          length: completedSection.value.length,
        };
      } else if (completedSection.value) {
        if (completedSection.type === "section-options") {
          answer = {
            type: "option",
            value: completedSection.value,
          };
        } else {
          answer = {
            type: "other",
            value: completedSection.value,
          };
        }
      } else {
        answer = {
          type: "single-translation-key",
          value: "summary.answers.noAnswer",
        };
      }
      return {
        name,
        answer,
        step: this.visibleSections.findIndex(
          (section) => section.name === completedSection.name
        ),
      };
    });
  }

  @Mutation
  SET_FORM_VALIDATION_STATE({ name, valid }: { name: string; valid: boolean }) {
    const sectionValidation = findSectionByName(this.sections, name)
      ?.validation;
    if (sectionValidation) {
      sectionValidation.valid = valid;
    }
  }

  @Mutation
  SET_FORM_VALIDATION_ERROR_MESSAGE({
    name,
    errorMessage,
  }: {
    name: string;
    errorMessage: string;
  }) {
    const sectionValidation = findSectionByName(this.sections, name)
      ?.validation;
    if (sectionValidation) {
      sectionValidation.errorMessage = errorMessage;
    }
  }

  @Mutation
  UNSET_FORM_VALIDATION_ERROR_MESSAGE({ name }: { name: string }) {
    const sectionValidation = findSectionByName(this.sections, name)
      ?.validation;
    if (sectionValidation) {
      sectionValidation.errorMessage = "";
    }
  }

  @Mutation
  SET_FORM_VALUE({ name, value }: SectionNameAndValuePayload) {
    const section = findSectionByName(this.sections, name);
    if (section) {
      section.value = value;
    }
  }

  @Mutation
  SET_FIELD_INVISIBLE({ name }: SectionNamePayload) {
    const section = findSectionByName(this.sections, name);
    if (section) {
      section.visible = false;
    }
  }

  @Mutation
  SET_FIELD_VISIBLE({ name }: SectionNamePayload) {
    const section = findSectionByName(this.sections, name);
    if (section) {
      section.visible = true;
    }
  }

  @Mutation
  SET_FIELD_OPTIONS({
    name,
    options,
  }: SectionNamePayload & { options: string[] }) {
    const section = findSectionByName(this.sections, name);
    if (section) {
      (section as SectionOptions).options = options;
    }
  }

  @Mutation
  SET_STEP({ step }: { step: number }) {
    this.step = step;
  }

  @Mutation
  SET_STEP_DIRECTION({ stepDirection }: { stepDirection: StepDirection }) {
    this.stepDirection = stepDirection;
  }

  @Mutation
  SET_LOADING() {
    this.loading = true;
  }

  @Mutation
  UNSET_LOADING() {
    this.loading = false;
  }

  @Action
  setVisibleSections() {
    this.sections.forEach((s) => {
      const hidden =
        i18n.t(["formConfig", s.name, "visible"].join(".")) === "HIDDEN";
      if (hidden) {
        this.SET_FIELD_INVISIBLE({ name: s.name });
      }
    });
  }

  @Action
  async hideInternetConnectionQuestion() {
    this.SET_FIELD_INVISIBLE({
      name: "internetConnection",
    });
  }

  @Action
  async setInternetConnectionOptions({ options }: { options: string[] }) {
    this.SET_FIELD_OPTIONS({
      name: "internetConnection",
      options,
    });
  }

  @Action
  async submitForm({ orderId, secret }: { orderId: string; secret: string }) {
    try {
      this.SET_LOADING();
      await httpClient.put(
        `orders/${orderId}/homecheck`,
        this.getFormSectionValues,
        {
          headers: {
            authorization: `Secret ${secret}`,
          },
        }
      );
      await router.replace({ name: ROUTE_NAMES.COMPLETED });
    } finally {
      this.UNSET_LOADING();
    }
  }

  @Action
  async setFormValue({ name, value }: SectionNameAndValuePayload) {
    if (this.hideOtherStepForCurrentStep) {
      const stepToHide = this.hideOtherStepForCurrentStep.stepName;
      if (
        typeof value === "string" &&
        this.hideOtherStepForCurrentStep.oneOf.includes(value)
      ) {
        this.SET_FIELD_INVISIBLE({ name: stepToHide });
      } else {
        this.SET_FIELD_VISIBLE({ name: stepToHide });
      }
    }
    this.SET_FORM_VALUE({ name, value });
    const rulesForSection = this.rulesForSection(name);
    if (rulesForSection) {
      /*
       * Prevent sending undefined/null to rules, to prevent errors like these:
       * https://sentry.io/organizations/defa/issues/2409260840/events/300b0b358df043109b0b316c5a2373f7/?environment=production&project=5389065
       */
      const { valid, errors } = await validate(value || "", rulesForSection, {
        name,
      });
      this.SET_FORM_VALIDATION_STATE({ name, valid });
      if (valid) {
        this.UNSET_FORM_VALIDATION_ERROR_MESSAGE({ name });
      } else {
        this.SET_FORM_VALIDATION_ERROR_MESSAGE({
          name,
          errorMessage: errors[0],
        });
      }
    }
  }

  @Action
  async goForward() {
    if (!this.isOnLastStep) {
      if (this.stepDirection !== "forward") {
        this.SET_STEP_DIRECTION({ stepDirection: "forward" });
      }
      this.SET_STEP({ step: this.getStep + 1 });
    }
  }

  @Action
  async goBackward() {
    if (!this.isOnFirstStep) {
      if (this.stepDirection !== "backward") {
        this.SET_STEP_DIRECTION({ stepDirection: "backward" });
      }
      this.SET_STEP({ step: this.getStep - 1 });
    }
  }
}

export const formModule = getModule(FormModule);
