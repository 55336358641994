













import { Component, Prop, Vue } from "vue-property-decorator";
import BaseOption from "@/components/BaseOption.vue";
import type { Nullable } from "@defa-as/utils";

@Component({
  components: {
    BaseOption,
  },
})
export default class InputOptions extends Vue {
  @Prop({ default: null }) value!: Nullable<string>;
  @Prop({ required: true }) options!: string[];

  get optionsWithKeyHints() {
    return this.options.map((option, index) => ({
      value: option,
      keyHint: String.fromCharCode(65 + index), // "A" is 65, "B" is 66, and so on
    }));
  }

  get isSelected() {
    return (value: string) => value === this.value;
  }
}
