









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseOptionKeyHint extends Vue {
  @Prop({ default: "" }) readonly keyHint!: string;
  @Prop({ default: false }) readonly selected!: boolean;

  get isSelected() {
    return this.selected;
  }
}
