import { AxiosError } from "axios";
import {
  captureExceptionWithSentry,
  shouldErrorBeReported,
  userFriendlyErrorMessage,
} from "@defa-as/utils";
import { orderModule } from "@/store/modules/order";
import { errorModule } from "@/store/modules/error";

export const responseInterceptorReportError = async (error: AxiosError) => {
  if (shouldErrorBeReported(error)) {
    captureExceptionWithSentry(error, orderModule.id);
    errorModule.SET_ERROR({
      errorMessage: userFriendlyErrorMessage(error),
    });
  }
  throw error;
};
