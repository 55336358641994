import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Form from "@/views/ViewForm.vue";

Vue.use(VueRouter);

export type RouteName = "Form" | "Completed" | "AlreadySubmitted" | "NotFound";

export const ROUTE_NAMES = {
  FORM: "Form",
  COMPLETED: "ViewCompleted",
  ALREADY_SUBMITTED: "ViewAlreadySubmitted",
  NOT_FOUND: "ViewNotFound",
  ERROR: "ViewError",
};

const routes: RouteConfig[] = [
  {
    path: "/:orderId(\\d{5,})/:secret",
    name: ROUTE_NAMES.FORM,
    component: Form,
    props: true,
  },
  {
    path: "/:orderId(\\d{5,})/:secret/completed",
    name: ROUTE_NAMES.COMPLETED,
    component: () => import("@/views/ViewCompleted.vue"),
  },
  {
    path: "/already-submitted",
    name: ROUTE_NAMES.ALREADY_SUBMITTED,
    component: () => import("@/views/ViewAlreadySubmitted.vue"),
  },
  {
    path: "/not-found",
    name: ROUTE_NAMES.NOT_FOUND,
    component: () => import("@/views/ViewNotFound.vue"),
  },
  {
    path: "/error",
    name: ROUTE_NAMES.ERROR,
    component: () => import("@/views/ViewError.vue"),
  },
  {
    path: "*",
    redirect: {
      name: ROUTE_NAMES.NOT_FOUND,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
