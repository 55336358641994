
























import { Component, Vue } from "vue-property-decorator";
import { setMainAndBackupTranslations } from "@defa-as/utils";
import {
  DefaLanguageSwitcher,
  DefaLoaderCircle,
  DefaNotificationError,
  DefaTestHeader,
  DefaFullPageLoader,
  DefaFooter,
} from "@defa-as/components";
import { errorModule } from "@/store/modules/error";
import { formModule } from "@/store/modules/form";
import { orderModule } from "@/store/modules/order";
import i18n from "@/i18n";

@Component({
  components: {
    DefaNotificationError,
    DefaLoaderCircle,
    DefaTestHeader,
    DefaLanguageSwitcher,
    DefaFullPageLoader,
    DefaFooter,
  },
})
export default class App extends Vue {
  languageLoading = false;

  async created() {
    try {
      this.languageLoading = true;
      await setMainAndBackupTranslations(i18n);
      formModule.setVisibleSections();
    } finally {
      this.languageLoading = false;
    }
  }

  get activeRetailerGroupHandle() {
    return orderModule.retailerGroup?.handle;
  }

  get activeRetailerGroupName() {
    return orderModule.retailerGroup?.name;
  }

  get formModule() {
    return formModule;
  }

  get errorModule() {
    return errorModule;
  }
}
