import { configure, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  defaultMessageConfigurationFn,
  getPersonalIdentificationNumberRule,
  inSweden,
} from "@defa-as/utils";
import i18n from "@/i18n";

export const setupValidation = () => {
  registerValidationRules();
  configureValidationMessages();
};

const registerValidationRules = () => {
  extend("required", required);
  if (inSweden()) {
    extend(
      "personalIdentificationNumber",
      getPersonalIdentificationNumberRule()
    );
  }
};

const configureValidationMessages = () => {
  configure({
    defaultMessage: defaultMessageConfigurationFn(i18n),
  });
};
