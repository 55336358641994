

















import { Component, Prop, Vue } from "vue-property-decorator";
import InputOptions from "@/components/InputOptions.vue";
import { formModule } from "@/store/modules/form";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";
import SectionNumber from "@/components/sections/section-parts/SectionNumber.vue";

@Component({
  components: {
    SectionNumber,
    SectionLabel,
    InputOptions,
  },
})
export default class SectionOptions extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly options!: string[];

  get formModule() {
    return formModule;
  }

  get value() {
    return this.formModule.getFieldValue(this.name);
  }

  onInput(value: string) {
    this.formModule.setFormValue({ name: this.name, value });
    setTimeout(this.formModule.goForward, 500);
  }
}
