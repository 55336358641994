import axios from "axios";
import {
  applyRequestInterceptorsInOrder,
  defaultTimeout,
  getApiBaseUrl,
  requestInterceptorAddCustomHeaders,
  responseInterceptorExtractData,
  withAxiosRetry,
} from "@defa-as/utils";
import { responseInterceptorReportError } from "@/http/interceptors";

const httpClient = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: defaultTimeout,
  headers: {
    "Content-Type": "application/json",
  },
});

withAxiosRetry(httpClient);

applyRequestInterceptorsInOrder({
  axiosInstance: httpClient,
  interceptors: [requestInterceptorAddCustomHeaders],
});
httpClient.interceptors.response.use(
  responseInterceptorExtractData,
  responseInterceptorReportError
);

export { httpClient };
