
















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaIconCaret } from "@defa-as/components";

@Component({
  components: {
    DefaIconCaret,
  },
})
export default class ProgressArrows extends Vue {
  @Prop({ default: true }) readonly showDownArrow!: boolean;
}
