






import { Component, Prop, Vue } from "vue-property-decorator";
import { formModule } from "@/store/modules/form";

@Component
export default class SectionNumber extends Vue {
  @Prop({ required: true }) readonly name!: string;

  get sectionNumber() {
    return formModule.getFieldSectionNumber(this.name);
  }
}
