import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { Homecheck, Order, Retailer } from "@defa-as/utils";
import { formModule } from "@/store/modules/form";
import { orderModule } from "@/store/modules/order";
import store from "@/store";
import { httpClient } from "@/http";
import router, { ROUTE_NAMES } from "@/router";

interface HomecheckResponse {
  homecheck: Homecheck;
  state: Order["state"];
  retailer: Retailer;
  orderLines: Order["orderLines"];
}
@Module({ name: "homecheck", dynamic: true, store, namespaced: true })
export class InitializationModule extends VuexModule {
  @Action
  async initialize({ orderId, secret }: { orderId: string; secret: string }) {
    formModule.SET_LOADING();
    try {
      const homecheck = await httpClient.get<
        HomecheckResponse,
        HomecheckResponse
      >(`/orders/${orderId}/homecheck`, {
        headers: {
          authorization: `Secret ${secret}`,
        },
      });
      if (homecheck.state.remoteHomeCheckCompleted.state) {
        return await router.push({
          name: ROUTE_NAMES.ALREADY_SUBMITTED,
          query: {
            orderId,
            secret,
          },
        });
      }
      await orderModule.initializeOrder({
        id: orderId,
        secret,
        retailer: homecheck.retailer,
        orderLines: homecheck.orderLines,
      });
    } catch (e) {
      if (e.response?.status >= 400) {
        if (e.response.status === 404) {
          return await router.push({
            name: ROUTE_NAMES.NOT_FOUND,
            query: {
              orderId,
              secret,
            },
          });
        } else {
          return await router.push({
            name: ROUTE_NAMES.ERROR,
            query: {
              orderId,
              secret,
            },
          });
        }
      }
      throw e;
    } finally {
      formModule.UNSET_LOADING();
    }
  }
}

export const initializationModule = getModule(InitializationModule);
