












































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseDropzone,
  DefaDropzoneFile,
  DefaImageCarousel,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { getApiBaseUrl } from "@defa-as/utils";
import { formModule } from "@/store/modules/form";
import { orderModule } from "@/store/modules/order";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";
import SectionNumber from "@/components/sections/section-parts/SectionNumber.vue";

@Component({
  components: {
    SectionNumber,
    SectionLabel,
    DefaImageCarousel,
    DefaBaseDropzone,
    DefaTransitionFadeOutIn,
    DefaBaseButton,
  },
})
export default class SectionUpload extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly progressionText!: string;

  get orderModule() {
    return orderModule;
  }

  get formModule() {
    return formModule;
  }

  get exampleImage() {
    return require(`@/assets/${this.name}-example.jpg`);
  }

  get fileUploadUrl() {
    return `${getApiBaseUrl()}/orders/${orderModule.id}/files`;
  }

  get files() {
    return this.formModule.getFieldValue(this.name);
  }

  get showContinueButton() {
    return this.files?.length;
  }

  onDropzoneInput(files: DefaDropzoneFile[]) {
    this.formModule.setFormValue({ name: this.name, value: files });
  }
}
