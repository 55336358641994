





































import { Component, Prop, Vue } from "vue-property-decorator";
import { debounce } from "lodash-es";
import { DefaBaseButton } from "@defa-as/components";
import { formModule } from "@/store/modules/form";
import SectionLabel from "@/components/sections/section-parts/SectionLabel.vue";
import SectionNumber from "@/components/sections/section-parts/SectionNumber.vue";

@Component({
  components: {
    SectionNumber,
    SectionLabel,
    DefaBaseButton,
  },
  inheritAttrs: false,
})
export default class SectionFreeText extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly progressionText!: string;
  onInput!: {
    (value: string): void;
    flush: () => void;
  };

  get formModule() {
    return formModule;
  }

  get value() {
    return this.formModule.getFieldValue(this.name);
  }

  get incompleteSectionsText() {
    if (this.formModule.getInCompleteSectionNumbers.length === 1) {
      return this.$tc("freeTextSection.incompleteSections", 1, {
        incompleteSectionNumber: this.formModule.getInCompleteSectionNumbers[0],
      });
    }
    const startingSectionNumbers = this.formModule.getInCompleteSectionNumbers
      .slice(0, -1)
      .join(", ");
    const lastSectionNumber = this.formModule.getInCompleteSectionNumbers.slice(
      -1
    );
    const incompleteSectionNumbers = this.$t("freeTextSection.sectionNumbers", {
      startingSectionNumbers,
      lastSectionNumber,
    });
    return this.$tc(
      "freeTextSection.incompleteSections",
      this.formModule.getInCompleteSectionNumbers.length,
      { incompleteSectionNumbers }
    );
  }

  created() {
    // Can't call 'flush' on `onInput` if declared in 'methods', see https://stackoverflow.com/questions/52987115/using-vue-js-how-to-you-flush-a-method-that-is-debounced-with-lodash
    this.onInput = debounce(function (this: SectionFreeText, value: string) {
      this.formModule.setFormValue({ name: this.name, value });
    }, 300);
  }

  onSubmit() {
    this.onInput.flush(); // Flush any values in case it's necessary
    this.formModule.goForward();
  }
}
